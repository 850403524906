.cookies-notification {
  min-height: 64px;
  background-color:rgba(0,0,0, 0.8);
  color: white;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 2000;
  font-size: 18px;
  padding: 15px 165px 15px 114px;

  display: grid;
  align-items: center;
  justify-content: flex-start;
  grid-template-columns: 1fr auto auto;
  grid-gap: 24px;

  @media screen and (max-width: 920px) {
    padding: 15px 114px 15px 114px;
  }

  @media screen and (max-width: 575px) {
    flex-direction: column;
    display: flex;
    padding: 15px 35px;
    grid-gap: 0;
  }

  .ant-row {
    width: 100%;
    padding: 0 15px;
  }

  .ant-col {
    justify-content: center;
    align-items: center;
    display: flex;
  }

  &--accept-btn {
    margin: 10px 0 10px 0;

    &_default {
      background: #FFFFFF;
      border-color: transparent;

      &:hover {
        border-color: transparent;
        background: #FFFFFF;
      }

      span {
        color: #FF0000;
      }
    }
  }
}
